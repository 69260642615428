@use '../../vars';

html {
}

#contact-page{
    margin: 50px auto 50px auto;
    display:flex;
    flex-flow: column;
    max-width:1200px;
    border-radius: 15px;
    display:flex;
    flex-flow: column wrap;
    h3 {
        color: vars.$black1;
        font-size: 60px;
        margin:20px 10px 10px;
        @media (max-width: 500px) {
            margin: 10px auto auto;
        }
    }
    input {
        flex-grow: 1;
        margin:10px;
        padding:5px;
        height:30px;
        max-width: 600px;
        border-radius: 15px;
        color: vars.$black1;
        &::placeholder {
            color: vars.$black1;
        }
    }
    input:nth-child(4) {
        max-width: 1200px;
        height: 60px !important;

    }
    button {
        @extend .b2;
        height: 40px;
        width: 200px;
        margin: 10px 10px 15px;
    }
}
