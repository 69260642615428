#notifications {
  max-width: 1475px;
  margin: auto;
  #notification-wrapper {
    border-radius: 15px;
    padding: 10px 10px 20px 10px;
    margin: 25px;
    background-color: #dddddd;
    height: 400px;
    h2 {
      text-align: center;
      font-size: 20px;
      margin-top: 25px;
    }
    //#toolbar {
    //  background-color: white;
    //  height: 40px;
    //  width: px;
    //  margin: 15px;
    //  border-radius: 22px;
    //  display: flex;
    //  justify-content: space-evenly;
    //  align-items: center;
    //}
  }

}
