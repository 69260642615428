@use '../../vars';

$tc-1: #d26dfa;
$tc-2: #e65270;
$tc-3: #c025fd;
$tc-4: #ec3056;

@keyframes rotate-colors {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

* {
  font-family: Inter, sans-serif;
}

html {
  padding: 0;
  margin: 0;
}

h1 {
  text-align: center;
}
h2 {
  margin: 5px;
}
h3 {
  margin:5px;
}


#home {
  padding: 0;
  margin: 0;
}

#welcome-block {
  margin-top: 0;
  background-color: black;
  width: 100%;
}

#d2 {
  height:300px;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding-bottom: 100px;
  h1,h2,h3 {
    text-align: center;
  }
  #airbnb-vrbo-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 50px;
    width: 90%;
    padding-bottom: 55px;
  }
  @media (min-width: 935px) {
    padding-bottom: 25px;
  }
  @media (max-width: 515px) {
    #airbnb-vrbo-wrapper {
      display:none;
    }
  }
}

#d3 {
  transition: background-color 0.5s ease;
  margin: 125px auto;
  width: 75%;
  max-width: 1200px;
  padding: 40px 25px 50px 25px;
  border-radius: 25px;
  @media (max-width: 530px) {
    border: none;
    margin: 125px 0 0 0;
    border-radius: 0px;
  }
  @keyframes enlarge{
    from {
      width:300px;
      height:300px;
    }
    to {
      width: 302px;
      height: 302px;
      box-shadow: 0px 5px 7px 0px rgba(5,5,5,0.5);
    }
  }
  #d3-comp-wrapper {
    .d3-comp {
      color: black !important;
      border: 4px solid grey;
      border-radius: 30px;
      width: 300px;
      height: 300px;
      display: flex;
      flex-flow: column;
      align-items: center;
      justify-content: center;
      margin: 10px;
      position: relative;

      .funky-button {
        position: relative;
        bottom: -15px;
        height: 27px;
        width: 125px;
        text-decoration: none !important;
        margin-bottom: 30px;
        text-align: center;
        padding-top: 5px;
        &:visited {
          text-decoration: none !important;
        }
      }

      h2 {
        position: absolute;
      }

      &:hover {
        box-shadow: 0px 5px 7px 0px rgba(5, 5, 5, 0.5);
      }

      &:active {
        box-shadow: none;
        color: black;
      }
    }
  }
}

#d4 {
  background-color: black;
  padding: 25px;
  h2 {
    color: white;
    text-align: center;
  }
  #d4-wrapper {
    max-width: 1200px;
    margin:auto;
  }
}

.heading-1 {
  margin: 0px;
  font-weight: 800;
  color: #fff;
  letter-spacing: -0.04em;
  font-size: 5rem;
}

.heading-2 {
  background-image: linear-gradient(135deg, $tc-1, $tc-2);
  background-size: 150% 150%;
  animation: rotate-colors 3s infinite alternate;
  color: #fff;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 108px;
  font-weight: 800;
  letter-spacing: -0.04em;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.just-for-centering {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}


@media (max-width: 400px) {
  #navbar-wrapper {
    display: none !important;
  }
  #welcome-block-inner {
    margin-top: 0 !important;
    padding-top: 100px !important;
  }
  .heading-1 {
    display: none;
  }
  .heading-2 {
    font-size: 5.5rem;
  }
  .b1 {
    width: 115px !important;
    height: 40px !important;
    font-size: 1rem !important;
  }
  #d2 {
    height: auto;
    padding: 0.5rem;
    margin: 2rem auto 1rem auto;
  }
  #d3 {
    padding: 0;
    margin: 2rem auto 2rem auto;

    #d3-comp-wrapper {
      margin: auto;
      .d3-comp{
        border-radius: 20px;
        width: 15rem !important;
        height: 6rem !important;
        h2 {
          top: 0.25rem !important;
          font-size: 1.5rem !important;
        }
        #uid1 { display: none; }
        img {
          display: none;
        }
      }
    }
  }


}