#propertiesWrapper {
  border-radius: 15px;
  padding: 10px 10px 20px 10px;
  margin: 25px;
  background-color: #dddddd;
  max-width: 1400px;
  margin: auto;
  h2 {
    text-align: center;
    font-size: 20px;
    margin-top: 25px;
  }
  #properties-alignment{
    display: flex;
    flex-flow: column;
    max-width: 1200px;
    width: 100%;
    height: 1000px;
    overflow: scroll;
    position: relative;
    margin:auto;
    padding-top: 50px;
    .stdBox {
      height: 80px;
      flex-grow: 1;
      margin: 5px;
      padding:10px;
      border-radius: 15px;
      border: 2px solid black;
      background-color: white;
    }
  }
}
