@use '../../vars';

form {
  margin: 30px auto 0px;
  display:flex;
  flex-flow: column wrap;
  max-width: 750px;
  padding: 5px 15px 15px;
  border: 3px solid black;
  border-radius: 15px;
  background-color: vars.$white1;
  input {
    flex-grow: 1;
    margin:10px;
    padding:5px;
    height:30px;
    background-color: transparent;
    border: 3px solid black;
    border-radius: 10px;
    color: black;
    &::placeholder {
      color:black;
    }
  }
}


.Banner {
  background-image: linear-gradient(135deg, #d26dfa, #e65270);
  color: white;
  height: 200px;
  margin: 25px 0 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  h2 {
    text-align: center;
  }
  a {
    color: black;
  }
}