@use '../../vars';

#signup-form {
    margin: 50px auto 0px;
    display:flex;
    flex-flow: column wrap;
    max-width: 750px;
    padding: 5px 15px 15px;
    border: 3px solid black;
    border-radius: 15px;
    h4 {
        font-size: 40px;
        margin:3px;
    }
    input {
        flex-grow: 1;
        margin:10px;
        padding:5px;
        height:30px;
        background-color: transparent;
        border: 3px solid black;
        border-radius: 10px;
        color: black;
        &::placeholder {
            color:black;
        }
    }
    button {
        @extend .b2;
        margin: 10px;
        height: 40px;
        width: 200px;
    }
    div {
        display:flex;
        flex-flow: row;
        align-items: center;
        p {
            margin-right: 4px;
        }
    }
}