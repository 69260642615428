
$font1: 15px;

* {
  font-weight:800;
  font-family: Inter, sans-serif;
}

#wrapper {
  display:flex;
  justify-content: flex-end;
  padding:25px 20px;
  background-color: rgba(25,25,25,1);

  * {
  text-decoration: none;
  color:white;
  font-size:$font1;
  margin:4px;
  padding:5px 12px;
  }
}

#logo {
  margin-right: auto;
  display: flex;
  align-items: center;
  img {
    height: 20px;
    padding:0px;
    margin:0px;
  }
  h1 {
    font-size:20px;
    font-weight: 800;
    padding:0px;
    margin:0px 5px;
  }
}
